<template>
  <section id="qc-chart-result-listing" class="all-cases-view">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="7">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li v-if="hasPermission('qc_charts', 'upload_new')">
                  <el-button
                    class="btn button-with-icon"
                    @click="goToUploadFiles()"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/upload-file.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/upload-file-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    Upload Files
                  </el-button>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view-new" v-loading="loading">
        <div class="listings listings__table listings__all-cases">
          <el-table
            :data="getAllCharts.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column
              prop="name"
              label="FILE NAME"
              min-width="300"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span class="order-files ml-5">
                    <img
                      src="@/assets/images/orders-table-file.svg"
                      alt="icon"
                    />
                  </span>
                  <span id="patient-name" class="ml-5">
                    {{ scope.row.name }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="CASE TYPE"
              min-width="150"
              prop="case_type"
              sortable="custom"
            >
              <template #default="scope">
                <div class="case-type">
                  <div class="case-type">
                    <span class="case-data-type">
                      {{ scope.row.case_type }}</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              min-width="140"
              prop="status"
              sortable="custom"
            >
              <template #default="scope">
                <div class="column-status">
                  <span class="el-dropdown-link">
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      scope.row.status
                    }}</el-tag>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="result_date"
              label="RESULT DATE"
              min-width="130"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.result_date" class="d-flex">
                  <div class="date pr-10">
                    {{ formatDate(scope.row.result_date) }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="PROGRESS"
              min-width="130"
              sortable="custom"
            >
              <template v-slot="scope">
                <div class="progress-btn">
                  <span
                    content="Process result file to prepare results"
                    placement="top"
                    ><el-button
                      type="text"
                      class="txt-warning"
                      @click="processReport(scope.row)"
                      v-if="showProcessReport(scope.row)"
                      >Process File</el-button
                    ></span
                  >

                  <span content="File is processed for QC Data" placement="top">
                    <el-button
                      type="text"
                      v-if="
                        scope.row.status !== 'TO-START' &&
                        scope.row.file_processed
                      "
                      disabled
                      class="generate-btn"
                      >Processed</el-button
                    >
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="UPLOADED BY"
              min-width="200"
              prop="uploaded_by"
              sortable="custom"
            >
              <template #default="scope">{{
                scope.row.uploaded_by.first_name +
                " " +
                scope.row.uploaded_by.last_name
              }}</template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              min-width="130"
              prop="created_at"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="UPDATED ON"
              min-width="130"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.updated_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.updated_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="90"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li>
                    <el-button
                      class="view-button"
                      @click="downloadFile(scope.row)"
                      title="Download File"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/download-new-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/download-new.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li v-if="hasPermission('qc_charts', 'upload_new')">
                    <el-button
                      class="view-button"
                      @click="onDeleteFile(scope.row)"
                      title="Delete File"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/delete-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/delete-deactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout="prev, pager, next, jumper, sizes, total"
            :total="getAllCharts.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import FilterHelper from "@/mixins/FilterHelper";
import caseConstants from "@/config/constants/caseConstants";
import { casesSearchOptions } from "@/config/constants/searchConstants";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  name: "",
  components: {},
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  mixins: [FilterHelper, UserPermissionsHelper],
  data() {
    return {
      scrollPosition: null,
      page: 1,
      pageSize: 25,
      loading: false,
      orderBy: "",
      orderType: "",
      caseTypes: caseConstants.CASE_TYPES_DATA,
      searchOptions: casesSearchOptions,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      search_string: "",
      hospital: "",
      date_of_birth: "",
      physician: "",
      searchBy: "",
      searchByKey: ["PatientName", "accession_id", "EMR"],
    };
  },
  computed: {
    getStatusType() {
      return (status) => {
        if (status == "COMPLETE") {
          return "success";
        } else if (status == "TO-START") {
          return "primary";
        } else {
          return "success";
        }
      };
    },
    isSearchByInput() {
      return this.searchByKey.indexOf(this.searchBy) != -1 ? true : false;
    },
    ...mapGetters("QCChartResultFiles", ["getAllCharts"]),
    ...mapGetters("respiratoryFileUpload", [
      "getUploadedDocumentDetails",
      "getGenerateReportStatus",
      "getGeneratedReportsFromFileUploads",
      "getGenerateFailedReportStatus",
      "getFileDeleteStatus",
      "getFileDownloadUrl",
      "getProcessReportStatus",
    ]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
  },
  mounted() {
    this.setQueryParamsData();
    this.fetchAllQCResults();
  },
  methods: {
    async downloadFile(doc) {
      await this.$store.dispatch("respiratoryFileUpload/fetchFileDownloadUrl", {
        path: doc.path,
      });

      let url = this.getFileDownloadUrl.download_url;
      window.open(url, "_blank");
    },
    onDeleteFile(doc) {
      let params = {
        id: doc._id,
      };
      this.$prompt("Are you sure to delete the Result file ?", "Confirm", {
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        inputPlaceholder: "Reason for deleting",
        inputValidator: (value) => {
          return value.trim().length == 0 ? "Reason is required" : true;
        },
      }).then(({ value }) => {
        params.data_delete_reason = value;
        this.deleteFile(params);
      });
    },

    async updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    async deleteFile(params) {
      try {
        await this.$store.dispatch(
          "respiratoryFileUpload/deleteSingleFile",
          params
        );

        if (this.getFileDeleteStatus) {
          successNotification("Result file deleted successfully");
          this.page = 1;
          this.fetchAllQCResults();
        }
      } catch (err) {
        console.log(err);
        errorNotification("Error at deleting result file");
      }
    },
    showProcessReport(data) {
      if (data.case_type == "TOXICOLOGY" && !data.file_processed) {
        return true;
      }
      return false;
    },
    async processReport(report) {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "respiratoryFileUpload/processReport",
          report._id
        );
        if (this.getProcessReportStatus) {
          successNotification("File Processed Successfully");
          await this.fetchAllQCResults();
        } else {
          errorNotification("Error in processing Report");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        errorNotification("Error in processing Report");
      }
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedOrderBy) {
        this.orderBy = this.$route.query.selectedOrderBy;
      }
      if (this.$route.query.selectedOrderType) {
        this.orderType = this.$route.query.selectedOrderType;
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedPageSize: params.limit,
        selectedOrderBy: params.order_by,
        selectedStatus: params.status,
        selectedOrderType: params.order_type,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchAllQCResults();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllQCResults();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllQCResults();
    },
    async fetchAllQCResults() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("QCChartResultFiles/fetchAllCharts", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
        only_qc_data_file: true,
      };
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    goToUploadFiles() {
      this.$router.push({
        name: "QCChartFileUpload",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
  },
};
</script>

<style lang="scss">
#qc-chart-result-listing {
  .order-files {
    img {
      position: relative;
      top: 3px;
      right: 4px;
    }
  }
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
    }
  }

  .progress-btn {
    .el-button {
      min-height: 0;
      padding: 0;
      color: #5162fd;
      font-size: 14px;
      font-weight: 500;
      &.generate-btn {
        color: #5162fd;
      }

      &.process-btn {
        color: #f28b0c;
      }
      &.txt-warning {
        color: #f28b0c;
      }

      &.is-disabled {
        opacity: 0.7;
      }
    }
  }
}
</style>
